import { isDesktop } from '../constants/breakpoints';
import { onClickOutside } from '../utils/on-click-outside';
import form from '../utils/form';
import account from './account';

let modalIsOpen = false;
let eventHandler: (e: MouseEvent) => void;
let modalEl: HTMLElement;

document.addEventListener('DOMContentLoaded', function() {
  modalEl = document.querySelector('.m-modal--top') as HTMLElement;
});

// Helpers
const showModal = () => {
  if (modalIsOpen) return;

  // Scroll to the top of the page on desktop
  if (isDesktop()) window.scrollTo(0, 0);

  document.removeEventListener('click', eventHandler);
  document.body.classList.add('modal-is-open');
  modalIsOpen = true;

  eventHandler = onClickOutside(modalEl, hideModal);
}

const hideModal = () => {
  document.body.classList.remove('modal-is-open');
  document.removeEventListener('click', eventHandler);
  modalIsOpen = false;
}

const updateModalType = (type: string) => {
  document.body.dataset.modalType = type;
}

const enableCloseButton = (section: HTMLElement) => {
  const btnClose = Array.from(section?.querySelectorAll('.m-modal__close') || []);
  
  // we could have multiple close buttons
  btnClose?.forEach(btn => {
    btn?.addEventListener('click', (e) => {
      hideModal();
    });
  });
}

document.addEventListener('liquid-ajax-cart:request-end', event => {
  enableCloseButton(modalEl);
});

const fetchAndShowModalContent = async (section_id: string, contentSelector: string): Promise<void> => {
  const url = `/?section_id=${section_id}`;
  
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      return response.text();
    })
    .then(data => {
      const modalInner = modalEl?.querySelector('.m-modal__inner');
      if (modalInner) {
        modalInner.innerHTML = data;
        const content = modalInner.querySelector(contentSelector);
        enableCloseButton(content as HTMLElement);

        if (section_id === 'ajax-cart') updateModalType('cart');
        else updateModalType('customer');

        showModal();
      }
    })
    .catch(error => {
      console.error(`Error fetching content from ${url}:`, error);
    });
}

// Modal methods
const showCart = () => {
  fetchAndShowModalContent('ajax-cart', '.m-cart').then(() => {
    
  });
}

const showLogin = () => {
  fetchAndShowModalContent('form-login', '.m-login').then(() => {
    const btnRegister = modalEl?.querySelector('.m-login__btn-register');
    // const btnLogin = modalEl?.querySelector('.m-customer__submit button');
    btnRegister?.addEventListener('click', (e) => {
      e.preventDefault();
      hideModal();
      showRegister();
    });

    const formEl = modalEl?.querySelector('.m-customer__form') as HTMLFormElement;
    const formErrors = modalEl?.querySelector('.m-customer__errors') as HTMLElement;

    if (formEl && formErrors) {
      form.handleSubmit(formEl, formErrors, () => window.location.reload());
    }
  });
}

const showRegister = () => {
  fetchAndShowModalContent('form-register', '.m-register').then(() => {
    const formEl = modalEl?.querySelector('.m-customer__form') as HTMLFormElement;
    const formErrors = modalEl?.querySelector('.m-customer__errors') as HTMLElement;

    if (formEl && formErrors) {
      form.handleSubmit(formEl, formErrors, () => {
        hideModal();
        showLogin();
      });
    }
  });
}

const showAccount = () => {
  const initAccount = () => {
    const forms = modalEl?.querySelectorAll('.m-customer__address-form') as NodeListOf<HTMLFormElement>;
    const deleteForms = modalEl?.querySelectorAll('.m-account__delete-address') as NodeListOf<HTMLFormElement>;
    // const formErrors = modalEl?.querySelector('.m-customer__errors') as HTMLElement;

    const allForms = Array.from(forms).concat(Array.from(deleteForms));
    allForms?.forEach(formEl => {
      form.handleSubmit(formEl, null, () => {
        hideModal();
        showAccount();
      });
    });

    account.init();
  }

  fetchAndShowModalContent('customer-account', '.m-account').then(initAccount);
}

export const modal = {
  showCart,
  showLogin,
  showRegister,
  showAccount
};

export default modal;